<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: flex-end">
      <div
        style="
          margin-right: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 30px;
        "
      >
        做作业时间段:
      </div>
      <el-date-picker
        v-model="value"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 20px"
        @click="search"
        >搜 索</el-button
      >
      <el-button size="mini" type="primary" @click="download" >下载</el-button>

    </div>
    <el-card style="margin-top: 20px">
      <el-table :data="tableData" v-loading="tableLoading" :default-sort = "{prop: 'grade_count', order: 'descending'}" height="650px" >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>

        <el-table-column prop="nickname" label="姓名" width="180" align="center">
        </el-table-column>
        <el-table-column prop="school_name" label="学校" width="180" align="center">
        </el-table-column>
        <el-table-column prop="grade_count" label="成绩" align="center">
        </el-table-column>

        <el-table-column
          v-for="(v, i) in headers"
          :key="i"
          :prop="v"
          :label="v"
          width="150"
          align="center"
        >
          <template #header>
            <div v-html="v"></div>
          </template>
          <template slot-scope="scope">
            <div>
              {{ scope.row.question_list[i]?.grade }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div
        class="pagination"
        style="
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>

  <!-- <question-census
      :columns="columns"
      table-url="/xapi/question.other/listInfo"
      census-url="/xapi/question.other/censusInfo"
  >
    <template slot="action" slot-scope="scope">
      <slot name="action" v-bind="scope">
        <el-button :disabled="!$isPowers('examEdit')" @click="$handleRoute({id:scope.row.other_id,log_id:scope.row.log_id},'matchTiDanRecord')" type="text" size="small">详情</el-button>
      </slot>
    </template>
  </question-census> -->
</template>

<script>
import QuestionCensus from "@/views/api/teaching/question/census";
export default {
  components: { QuestionCensus },
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 50,
      headers: [],
      end_time: "",
      other_id: 0,
      start_time: "",
      group_id: 0,
      value: [],
      tableLoading:false,
      course_id:0,//课程id
      // columns:[
      //   {prop:'log_id',label:'ID',width:'80' , align:'center'},
      //   {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',template:(scope)=>{
      //       return '<font style="color:red;">'+(scope.row.matchStr || '')+'</font> - <font style="color:green;">'+(scope.row.create_user_name || '')+'</font> - '+this.$jquery("<font>"+scope.row.other_title+"</font>").text();
      //     },minWidth:'320px' , align:'center'},
      //   {prop:'member_name',label:'用户',width:'140' , align:'center'},
      //   {prop:'grade',label:'得分',width:'80' , align:'center'},
      //   {prop:'total_grade',label:'总分',width:'80' , align:'center'},
      //   {prop:'answer_time',label:'答题时间',width:'80' , align:'center'},
      //   {prop:'last_commit_date',label:'提交时间',width:'160' , align:'center'},
      //   {fixed:'right',label:'操作',width:'80' , slot:'action' , align:'center'},
      // ],
    };
  },
  created() {
    console.log(this.$router.currentRoute, "this.$router.currentRoute");
    this.other_id = this.$router.currentRoute.query.other_id;
    this.group_id = this.$router.currentRoute.query.group_id;
    this.course_id = this.$router.currentRoute.query.course_id;
    this.start_time = this.$router.currentRoute.query.start_time;
    this.end_time = this.$router.currentRoute.query.end_time;
setTimeout(() => {
  
  this.checkTotal();
}, 50);
  },
  methods: {
    // 查看答题统计
    checkTotal() {
      this.tableLoading=true
      this.$http
        .get("/api/v1_0_0.question_other/statistics_user", {
          params: {
            page_size: this.pageSize,
            page: this.page,
            other_id: this.other_id,
            group_id: this.group_id,
            start_time: this.start_time,
            end_time: this.end_time,
            course_id:this.course_id
          },
        })
        .then((res) => {
          this.tableLoading=false
          console.log(res, "查看答题统计");
            this.tableData = res.data.list;
            this.headers = res.data.question_title;
            this.total=res.data.total
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    download() {
      window.location.href =
        (location.baseURL || "") +
        `/api/v1_0_0.question_other/rank_log_down?other_id=${this.other_id}&course_id=${this.course_id}&group_id=${this.group_id}&user_id=${localStorage.getItem("user_id")}&user_token=` +
        encodeURIComponent(localStorage.getItem("user_token"));
    },
    search() {
      console.log(this.value, "时间");
      if(this.value  && this.value.length>0){
        this.start_time = this.value[0];
        this.end_time = this.value[1];
      }else{
        this.start_time=""
        this.end_time=""
      }
      console.log(this.start_time, "this.start_time");
      console.log(this.end_time, "this.end_time");
      this.checkTotal();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.checkTotal();
    },
    currentChange(e) {
      this.page = e;
      this.checkTotal();

    },
  },
};
</script>

<style scoped>
</style>